import { Link } from "@mui/material";
import { Container, Navbar, Nav } from "react-bootstrap";

import { ANCLAS } from "../../constants/routes";


import "../../styles/components/HeaderMain.scss";

export default function HeaderMain() {

  return (
    <>
      <Navbar
        className={`navmenu px-3`}
        expand="md"
        variant="dark"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navmenu__nav-items">
              <Link
                href={ANCLAS.ALEANTE}
                color="inherit"
                className='nav-link servicio'
                target="_blank" 
                rel="noopener noreferrer"
              >
                Aleante
              </Link>
              <Link
                href={ANCLAS.LABORATORIO}
                color="inherit"
                className='nav-link servicio'
                target="_blank" 
                rel="noopener noreferrer"
              >
                Laboratorio
              </Link>   
              <Link
                href={ANCLAS.Cuidarte}
                color="inherit"
                className='nav-link servicio'
                target="_blank" 
                rel="noopener noreferrer"
              >
                CuidArte
              </Link>
              <Link
                href={ANCLAS.DESARROLLO}
                color="inherit"
                className='nav-link servicio'
                target="_blank" 
                rel="noopener noreferrer"
              >
                Tecnologías
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};