import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import HeaderMain from "./components/Header/HeaderMain";
import Loading from "./components/Loading/Loading";
import { ANCLAS } from "./constants/routes";

import homeCorporativo from '../src/assets/images/homeCorporativo.jpg';
import textoCorporativo from '../src/assets/images/textoCorporativo.svg';

import aleanteCorporativo from '../src/assets/images/aleanteCorporativo.jpg';
import textoAleante from '../src/assets/images/textoAleante.svg';
import botonAleante from '../src/assets/images/botonAleante.svg';

import laboratorioCorporativo from '../src/assets/images/laboratorioCorporativo.jpg';
import textoLaboratorio from '../src/assets/images/textoLaboratorio.svg';
import botonLaboratorio from '../src/assets/images/botonLaboratorio.svg';

import cuidarteCorporativo from '../src/assets/images/cuidarteCorporativo.jpg';
import textoCuidarte from '../src/assets/images/textoCuidarte.svg';
import botonCuidarte from '../src/assets/images/botonCuidarte.svg';

import desarrolloCorporativo from '../src/assets/images/desarrolloCorporativo.jpg';
import textoDesarrollo from '../src/assets/images/textoDesarrollo.svg';
import botonDesarrollo from '../src/assets/images/botonDesarrollo.svg';

import './App.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

function App() {
  const [cargar, setCargar] = useState<boolean>(false);
  const [animarImagen, setAnimarImagen] = useState<boolean>(false);

  useEffect(() => {
    setCargar(true);
    setTimeout(() => {
      setAnimarImagen(true); // Activar la animación
      setCargar(false);
    }, 500);
  }, []);

  const fondo = () => {
    return {
      minHeight: "100vh",
    };
  };

  const handleSlideChange = () => {
    setAnimarImagen(false); // Activa la animación cada vez que cambies de diapositiva
    setTimeout(() => {
      setAnimarImagen(true); // Activar la animación
    }, 500);
  };

  return (
    <>
      {cargar && <Loading />}
      <main
        className="d-flex flex-column justify-content-between"
        style={fondo()}
      >
        <HeaderMain />
        <Swiper
          spaceBetween={30}
          loop={true}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          onSlideChange={handleSlideChange}
        >
          <SwiperSlide>
            <div className='contenedor'>
              <div className={`background_container ${animarImagen ? 'imagenFondoAnimada' : ''}`}>
                <img
                  src={homeCorporativo}
                  alt="Fondo Aleante"
                  className="imagenFondo"
                />
              </div>
              <div className='imagenTexto'>
                <img
                  src={textoCorporativo}
                  alt="Fondo Aleante"                                
                />
                <span style={{paddingBottom:'1rem'}}>
                  Nuestro enfoque multidiciplinario abarca topografía, análisis quimicos metalurgicos, seguridad, software y cuidado de la salud.                
                </span>
                <span>
                  Descubre cómo nuestras empresas conectan cada aspecto de tu negocio.
                </span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='contenedor'>
              <div className={`background_container ${animarImagen ? 'imagenFondoAnimada' : ''}`}>
                <img
                  src={aleanteCorporativo}
                  alt="Fondo Aleante"
                  className="imagenFondo"
                />
              </div>
              <div className='imagenTexto'>
                <img
                  src={textoAleante}
                  alt="Fondo Aleante"                                
                />
                <span style={{paddingBottom:'1rem'}}>
                  Empresa especializada en el desarrollo de Ingenierías y supervisión de obra tecnificada, comprometidos con la calidad y la mejora continua. Trabajamos con los más altos estandares de calidad, seguridad y cuidado del medio ambiente, cumpliendo con la normativa legal vigente.
                </span>
                <a href={ANCLAS.ALEANTE} target="_blank" rel="noopener noreferrer">
                  <img
                    src={botonAleante}
                    alt="Fondo Aleante"
                  />
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='contenedor'>
              <div className={`background_container ${animarImagen ? 'imagenFondoAnimada' : ''}`}>
                <img
                  src={laboratorioCorporativo}
                  alt="Fondo Laboratorio"
                  className="imagenFondo"
                />
              </div>
              <div className='imagenTexto'>
                <img
                  src={textoLaboratorio}
                  alt="Fondo Laboratorio"                                
                />
                <span style={{paddingBottom:'1rem'}}>
                  Nuestro compromiso con la investigación de procesos mineros y metalúrgicos, nos respaldan.
                </span>
                <a href={ANCLAS.LABORATORIO} target="_blank" rel="noopener noreferrer">
                  <img
                    src={botonLaboratorio}
                    alt="Fondo Laboratorio"
                  />
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='contenedor'>
              <div className={`background_container ${animarImagen ? 'imagenFondoAnimada' : ''}`}>
                <img
                  src={cuidarteCorporativo}
                  alt="Fondo Laboratorio"
                  className="imagenFondo"
                />
              </div>
              <div className='imagenTexto'>
                <img
                  src={textoCuidarte}
                  alt="Fondo Laboratorio"                                
                />
                <span style={{paddingBottom:'1rem'}}>
                Empresa dedicada a brindar un servicio de calidad y calidez profesional a los pacientes en casa, dando un trato digno, respetuoso.
                </span>
                <a href={ANCLAS.Cuidarte} target="_blank" rel="noopener noreferrer">
                  <img
                    src={botonCuidarte}
                    alt="Fondo Laboratorio"
                  />
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='contenedor'>
              <div className={`background_container ${animarImagen ? 'imagenFondoAnimada' : ''}`}>
                <img
                  src={desarrolloCorporativo}
                  alt="Fondo Laboratorio"
                  className="imagenFondo"
                />
              </div>
              <div className='imagenTexto'>
                <img
                  src={textoDesarrollo}
                  alt="Fondo Laboratorio"                                
                />
                <span style={{paddingBottom:'1rem'}}>
                  Transformamos ideas en soluciones digitales excepcionales.
                </span>
                <a href={ANCLAS.DESARROLLO} target="_blank" rel="noopener noreferrer">
                  <img
                    src={botonDesarrollo}
                    alt="Fondo Laboratorio"
                  />
                </a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </main>
    </>
  );
}

export default App;
